@font-face{font-family:"bold"; src:url("./fonts/RobotoSlab-Bold.woff")}
@font-face{font-family:"medium"; src: url("./fonts/RobotoSlab-Medium.woff")}
@font-face{font-family:"regular"; src: url("./fonts/RobotoSlab-Regular.woff")}
@font-face{font-family:"light"; src:url("./fonts/RobotoSlab-Light.woff")}
@font-face{font-family:"thin";src: url("./fonts/RobotoSlab-Thin.woff")}
@font-face{font-family:"d-bold"; src:url("./fonts/DINCondensedBold.woff")}



.bold{font-family:"bold"}
.medium{font-family:"medium"}
.regular{font-family:"regular";}
.light{font-family:"light"}
.thin{font-family:"thin"}
.d-bold{font-family:"d-bold"}



.white{color: #fff;}
.black-color{color: #000}
.green{color: #00C08B;}
.blue{color: #000B8C}
.red{color: #F41938}
.yellow{color: #F9AC1C}
.dblue{color: #031083}


.black-bg{background: #000;}
.white-bg{background: #fff;}
.red-bg{ background: #F41938;}
.blue-bg{background: #000B8C;}
.yellow-bg{background: #F9AC1C;}
.lbue-bg{background: #F1F1F9;}
.dblue-bg{background: #031083}

.letterspacing1{letter-spacing: 5px}

body,html{height:100%!important}
body{font-family:"regular"!important;overflow-x:hidden; background-color: #F8F8FF !important; color: #000 !important; font-size: 14px !important}
.wrapper{max-width:2560px;margin:auto;width:100%}
.big-container{max-width:1470px; margin: auto;padding-left:35px;padding-right:35px; width: 100%}

.white{color: #fff;}
.black-color{color: #000}

.black-bg{background: #000;}
.white-bg{background: #fff;}

.letterspacing1{letter-spacing: 5px}

:focus{outline:none!important}
a{color:inherit}
a:hover{text-decoration:none!important;}
a:focus{outline:none!important;border:none!important;text-decoration:none!important}

.text-underline{text-decoration: underline !important}
.abs-anch{position: absolute; width: 100%; height: 100%; top: 0;left: 0; color: transparent !important;z-index: 0}
.borderbottom{border-bottom: 1px solid}
.list-inline li{display: inline-block}

.h-70{height: 70px;}

.pt-120{padding-top: 120px}
.pb-120{padding-bottom: 120px}
.pt-80{padding-top: 80px}
.pb-80{padding-bottom: 80px}
.pt-60{padding-top: 60px}
.pt-40{padding-top: 40px}
.pb-40{padding-bottom: 40px}

.pl-15{padding-left: 15px !important}
.pl-40{padding-left: 40px !important}
.pl-50{padding-left: 50px !important}

.mt-10{margin-top:10px;}
.mt-20{margin-top:20px;}
.mr-30{margin-right:30px;}
.mr-10{margin-right:10px;}
.mb-10{margin-bottom:10px;}

.nomargin-top{margin-top:0!important}
.nomargin-bottom{margin-bottom:0!important}
.nomargin-left{margin-left:0!important}
.nomargin-right{margin-right:0!important}
.nopadding-top{padding-top:0!important}
.nopadding-bottom{padding-bottom:0!important}
.nopadding-left{padding-left:0!important}
.nopadding-right{padding-right:0!important}

.display-table{display:table;width:100%;table-layout:fixed;height:100%;margin:auto}
.display-table .display-cell{display:table-cell;vertical-align:middle}
.display-table .display-cell.v-top{vertical-align: top}
.display-table .display-cell.v-bottom{vertical-align: bottom}

input:focus::-webkit-input-placeholder{color:transparent !important}
input:focus:-moz-placeholder{color:transparent !important}
input:focus::-moz-placeholder{color:transparent !important}
input:focus:-ms-input-placeholder{color:transparent !important}
textarea:focus::-webkit-input-placeholder{color:transparent !important}
textarea:focus:-moz-placeholder{color:transparent !important}
textarea:focus::-moz-placeholder{color:transparent !important}
textarea:focus:-ms-input-placeholder{color:transparent !important}


select {-webkit-appearance: none;position: relative;background-image: url("../assets/images/select-arrow.svg");background-position: right 15px center!important;    background-repeat: no-repeat!important;}

.show-1024{display:none!important}
input,select,texarea{border-radius:0!important}
strong,b{font-weight:400!important;font-family:bold}



/******* header ********/

.header{position:fixed;width:100%;max-width:2560px;margin:auto;left:0;right:0;transition:all ease-in-out .3s; background: #DEDEEA; padding: 0px 15px; z-index: 9}


input[type="search"]::-webkit-input-placeholder{color:#031083}
input[type="search"]:-moz-placeholder{color:#031083}
input[type="search"]::-moz-placeholder{color:#031083}
input[type="search"]:-ms-input-placeholder{color:#031083}

/******* header ********/



/******* left panel ********/

.panel-menu ul li{padding:30px 0  30px 40px}
.panel-menu ul li a{font-size: 15px; color: #fff}
.panel-menu ul li.active a{color: #FE9A1B}


.panel-menu.admin-user ul li:first-child{ background: url("../assets/images/icon_dashboard.svg") no-repeat left center;}
.panel-menu.admin-user ul li:nth-child(2){ background: url("../assets/images/digital-signature.svg") no-repeat left center;}
.panel-menu.admin-user ul li:nth-child(3){ background:  url("../assets/images/user.svg") no-repeat left center;}
.panel-menu.admin-user ul li:nth-child(4){ background: url("../assets/images/error.svg") no-repeat left center;}
.panel-menu.admin-user ul li:nth-child(5){ background: url("../assets/images/Wifi.svg") no-repeat left center;}

.panel-menu.admin-user ul li.active:first-child{ background: url("../assets/images/icon_dashboard-delected.svg") no-repeat left center;}
.panel-menu.admin-user ul li.active:nth-child(2){ background: url("../assets/images/digital-signature-selected.svg") no-repeat left center;}
.panel-menu.admin-user ul li.active:nth-child(3){ background:  url("../assets/images/user-selected.svg") no-repeat left center;}
.panel-menu.admin-user ul li.active:nth-child(4){ background: url("../assets/images/error-selected.svg") no-repeat left center;}
.panel-menu.admin-user ul li.active:nth-child(5){ background: url("../assets/images/Wifi-selected.svg") no-repeat left center;}



/***************/



/*  16/06/2020 */




/*  17/06/2020 */

.font-11,.font-12{font-size: 11px;}
.font-13{font-size:13px;}
.font-16{font-size:16px;}
.font-15{font-size:15px;}
.font-14,.font-18{font-size: 14px;}
.font-20{font-size: 15px;}
.font-36{font-size: 21px; line-height: 25px}
.font-40{font-size: 25px; line-height: 25px}
.font-45{font-size: 30px; line-height: 30px}
.font-35{font-size: 35px; line-height: 35px}
.font-50{font-size: 40px; line-height: 45px}
.font-54{font-size: 44px; line-height: 40px;}
.font-72{font-size: 72px;line-height: 75px;}



.pagination button {
    border: none;
    background: none;
    color: inherit;
    // padding:0;
}
.pagination ul li {
    padding: 15px;
    font-family: medium;
    color: #031083;
}
.pagination ul li.current-page {
	background: url("../assets/images/active-pagination.svg") no-repeat;    background-position: center;
    background-size: 100%;
}


/********** changes 30-06-20*********************/

.search-div input[type="submit"] {
    position: absolute;
    left: 0;
    background: url("../assets/images/Search.svg") no-repeat left center;
    color: transparent;
    border: none;
    top: 10px;
    width: 20px;
    cursor: pointer;
    height: 30px;
    background-size: 100%;
}

.user-profile {
    display: flex;
    align-items: center;
    height: 60px;
}
.user-profile a:hover {
    color: #fff;
}

.search-div {
    border-right: 1px solid rgba(255,255,255,0.45);
    margin-right: 35px;
    padding: 0 35px;
    height: 60px;
    border-left: 1px solid rgba(255,255,255,0.45);
}

.user__profile img {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    cursor: pointer;
}

input[type="search"] {
    background: transparent;
    border: none;
    color: #031083;
    padding: 0px 0px 0px 45px;
    line-height: 53px;
}


/******************* changes 02-07-20 **********************/

.font-28{font-size: 21px; line-height: 21px}
.font-24{font-size: 18px; line-height: 18px}


.curved-btn {
    display: table;
    padding:10px 25px;
    border-radius: 35px;
}


.notification {
    position: fixed;
    background: #f41938;
    right: 0;
    top: 20px;
    z-index: 10;
    padding: 15px;
    color: #fff;
    font-family: light; display: none;
    width: 300px;
}

.notification.success{background: #00b577}

.notification.show{display: block}
.notification span.font-24 {
    display: block;
    // text-align: center;
    margin-bottom: 5px;
}


/******************* 03-07-20 **********************/

.purple{color: #3F4BBC}
.purple-bg{background: #3F4BBC}

.lblue-bg{background: #0099DC}
.orange-bg{background: #FE9A1B}
.pink-bg{background: #D988D3}
.green-bg{background: #00B577}




/*********************** 07-07-20 ************************/

.logo img {
    width: 130px;
    margin: 0 auto 45px;
    display: block;
}

.left-panel {
    position: fixed;
    width: 215px;
    top: 0;
    height: 100%;
    z-index: 10;
    border-top-right-radius: 55px;
    border-bottom-right-radius: 55px;
    padding-top: 75px;
}
.panel-menu ul {    padding-left: 35px;}

.main-content {
    padding: 80px 0 30px 235px;
}


// .left-panel {
//     position: fixed;
//     width: 155px;
//     top: 0;
//     height: 100%;
//     z-index: 10;
//     border-top-right-radius: 55px;
//     border-bottom-right-radius: 55px;
//     padding-top: 75px;
// }

// .main-content {
//     padding: 80px 0 30px 155px;
// }

// .panel-menu ul {
//     padding-left: 15px;
// }

// .logo img {
//     width: 90px;
//     margin: 0 auto 15px;
//     display: block;
// }

// .pagination {
//     padding: 0px 0 30px 155px;
// }


.panel-menu.resident-user ul li:first-child{ background: url("../assets/images/icon_dashboard.svg") no-repeat left center;}
.panel-menu.resident-user ul li:nth-child(3){ background: url("../assets/images/digital-signature.svg") no-repeat left center;}
.panel-menu.resident-user ul li:nth-child(2){ background:  url("../assets/images/menu-rent.svg") no-repeat left center;}
.panel-menu.resident-user ul li:nth-child(4){ background: url("../assets/images/error.svg") no-repeat left center;}
.panel-menu.resident-user ul li:nth-child(5){ background: url("../assets/images/user.svg") no-repeat left center;}

.panel-menu.resident-user ul li.active:first-child{ background: url("../assets/images/icon_dashboard-delected.svg") no-repeat left center;}
.panel-menu.resident-user ul li.active:nth-child(3){ background: url("../assets/images/digital-signature-selected.svg") no-repeat left center;}
.panel-menu.resident-user ul li.active:nth-child(2){ background:  url("../assets/images/menu-rent-selected.svg") no-repeat left center;}
.panel-menu.resident-user ul li.active:nth-child(4){ background: url("../assets/images/error-selected.svg") no-repeat left center;}
.panel-menu.resident-user ul li.active:nth-child(5){ background:  url("../assets/images/user-selected.svg") no-repeat left center;}



.panel-menu.building-manager-user ul li:first-child{ background: url("../assets/images/icon_dashboard.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li:nth-child(2){ background: url("../assets/images/digital-signature.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li:nth-child(3){ background:  url("../assets/images/user.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li:nth-child(4){ background: url("../assets/images/error.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li:nth-child(5){ background: url("../assets/images/Wifi.svg") no-repeat left center;}

.panel-menu.building-manager-user ul li.active:first-child{ background: url("../assets/images/icon_dashboard-delected.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li.active:nth-child(2){ background: url("../assets/images/digital-signature-selected.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li.active:nth-child(3){ background:  url("../assets/images/user-selected.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li.active:nth-child(4){ background: url("../assets/images/error-selected.svg") no-repeat left center;}
.panel-menu.building-manager-user ul li.active:nth-child(5){ background: url("../assets/images/Wifi-selected.svg") no-repeat left center;}
















#root{
  height: 100%;
}

input[type="submit"]:disabled, :disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
}

.validation-message {
    font-size: 80%;
    font-weight: 400;
    padding-left: 30px;
}

.overflowScroll {
    overflow-y: scroll;
}
.overflowHidden {
    overflow-y: hidden;
}

.luxpod-success-message {
    padding: 120px 45px 0;
    width: 65%;
}

div#prevposts .modal-dialog {
	max-width: 960px;    margin: 10% auto 0;}

div#prevposts .modal-content {
    border-radius: 35px;
    padding: 45px 45px 0;
}
.popup-man img {
    width: 85%;
}
.popup-man {
    right: -130px;
    top: 60px;
}

span.modal-close {
    position: absolute;
    top: -140px;
    left: 0;
    right: 0;
    margin: auto;
    width: 100px; cursor: pointer
}
.modal-backdrop { background-color: #040934 !important;}
.modal-backdrop.show {    opacity: .88 !important;}
.modal.fade.show { display: block; }

.profile-menu-container {
    position: relative;

    .profile-menu {
        position: absolute;
        top: 40px;
        text-align: right;
        font-size: 16px;
    }

    a {
        color: #031083;
    }
    
    a:hover {
        background-color: #031083;
        color: #fff;
    }
}

.panel-menu li a {
    position: relative;
}
.badge {
    position: absolute;
    padding-left: 0.5em;
    padding-right: 0.55em;
    border-radius: 100%;
}
.badge-danger {
    top: -0.5em;
    right: -1.8em;
}
.my-history a {
    position: relative;
}
.badge-light {
    top: 0.5em;
    right: 0.4em;
    color: #031083;
    font-size: 12px;
}

.unread-bg {
    background-color: rgba(3,16,131,0.1) !important;
}

.phone-fields {
    input {
        padding-left: 12px !important;
    }

    input::-webkit-input-placeholder{font-family: regular; color: rgba(0, 0, 0, 0.3) !important; font-size: 0.9em;}
    input:-moz-placeholder{font-family: regular; color: rgba(0, 0, 0, 0.3) !important; font-size: 0.9em;}
    input::-moz-placeholder{font-family: regular; color: rgba(0, 0, 0, 0.3) !important; font-size: 0.9em;}
    input:-ms-input-placeholder{font-family: regular; color: rgba(0, 0, 0, 0.3) !important; font-size: 0.9em;}

    input:nth-child(1) {
        width: 28% !important;
        padding-left: 20px !important;
        // border-bottom-right-radius: 15px !important;
        // border-top-right-radius: 15px !important;
    }
    input:nth-child(2) {
        width: 28% !important;
        // margin-left: 10px;
        // margin-right: 10px;
        // border-radius: 15px !important;
    }
    input:nth-child(3) {
        width: 42% !important;
        // border-bottom-left-radius: 15px !important;
        // border-top-left-radius: 15px !important;
    }
}
.phone-fields::before {
    content: "+";
    font-size: 20px;
    line-height: 20px;
    position: absolute;
    top: 20px;
    left: 6px;
    font-weight: lighter;
}
.edit-resident-profile-details .phone-fields::before{
    color: #031083;
}






.react-datepicker-popper {
    margin-top: 0 !important;
    z-index: 10 !important;
}
.react-datepicker-popper[data-placement^="bottom"] {
    margin-top: 0 !important;
}
.react-datepicker__triangle {
    display: none !important;
}
.react-datepicker__header {
    padding-top: 0 !important;
}
.react-datepicker__day-name {
    color: #fff !important;
    background-color: #FE9A1B !important;
    margin: 0 !important;
    width: 2.2rem !important;
    line-height: 2.5rem !important;
}
.react-datepicker__day {
    margin: 0 !important;
    width: 2rem !important;
    line-height: 2.5rem !important;
}
.react-datepicker__day:hover {
    border-radius: 0 !important;
    background-color: #F93549 !important;
    color: #fff !important;
}
.react-datepicker__day--selected, .react-datepicker__day--keyboard-selected {
    border-radius: 0 !important;
    background-color: #F93549 !important;
}
.react-datepicker__day--selected:hover, .react-datepicker__day--keyboard-selected:hover {
    border-radius: 0 !important;
    background-color: #F93549 !important;
}
.react-datepicker__current-month {
    background-color: #F93549 !important;
    color: #fff !important;
    line-height: 2.5rem !important;
}
.react-datepicker__navigation.react-datepicker__navigation--previous {
    background: url("../assets/images/left_arrow_calendar.svg") no-repeat !important;
    height: 18px !important;
    width: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
}
.react-datepicker__navigation.react-datepicker__navigation--next {
    background: url("../assets/images/right_arrow_calendar.svg") no-repeat !important;
    height: 18px !important;
    width: 10px !important;
    margin: 0 !important;
    padding: 0 !important;
    border: none !important;
}
.react-datepicker__year-dropdown-container {
    position: relative;
    top: -30px;
    color: transparent;
    width: 120px;
}
// .react-datepicker__year-read-view--selected-year {
//     color: transparent;
// }
// .react-datepicker__year-read-view--selected-year:active {
//     color: transparent;
// }
.react-datepicker__header__dropdown {
    height: 0 !important;
}
.react-datepicker__year-dropdown, .react-datepicker__year-dropdown--scrollable {
    width: 100px !important;
    background-color: #fff !important;
    color: #000;
    left: 8px !important;
    scrollbar-width: none;
    scrollbar-color: #F93549 #fff;
    border-radius: 10px;
    height: 200px !important;
}
.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar {
  width: 8px;
}
.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar-track {
  background: #fff;
}
.react-datepicker__year-dropdown--scrollable::-webkit-scrollbar-thumb {
  background-color: #F93549;
  border-radius: 10px;
  border: 3px solid #F93549;
}
.react-datepicker__year-option {
    line-height: 25px !important;
    font-size: 14px;
}
.react-datepicker__year-option:hover {
    background-color: #F93549 !important;
    color: white;
}
.react-datepicker__year-read-view--down-arrow {
    border: none !important;
}

.react-datepicker-wrapper {
    width: 100%;
    position: relative;
}
.react-datepicker-wrapper:after {
    content: "";
    background: url('../assets/images/calendar.svg');
    position: absolute;
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    right: 15px;
    top: 19px;
}


.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0,0,0,0.6);
  z-index: 1111;
}
.loader:before {
  content: " ";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  max-width: .001%
}
.loader img {
  height: 100px;
  top: 45%;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto
}




/*=======================MEDIA QUERIES=======================*/

@media only screen and (max-width: 2560px) and (min-width: 1921px) {



}
@media (max-width:1680px){

}
@media (max-width:1600px){
	
}
@media (max-width:1480px){

}
@media (max-width:1366px){

}
@media (max-width:1199px){
.pt-120{padding-top: 60px}
.pb-120{padding-bottom: 60px}
.pt-80{padding-top: 40px}
.pb-80{padding-bottom: 40px}

}
@media (max-width:1024px){
.show-1024{display:block!important}
.hide-1024{display:none!important}
.display-table .display-cell{display: block; width: 100% !important}

}
@media (max-width:992px){
}
@media (max-width:768px){

	
}
@media (max-width:600px){




}
@media (max-width:480px){
}
@media (max-width:375px){
}

@media (max-width:320px){
}

