
.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.post_details{border-radius: 15px}
.post_details.no-image .post__details {
    width: 100%;
}

.post__details {
       min-height: 150px;
    padding: 20px 35px 40px; position: relative;}
.post__image {
    width: 150px;
	height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 15px;
}
.post__image img {
    border-radius: 15px;
    height: 100%;
}
.post_time.font-11 {
    position: absolute;
    bottom: 20px;
}
.post_details{position: relative}
.notices-list .row .col-md-4{margin-bottom: 30px}

.notices-list .row .col-md-4:nth-child(3n+1) .post_details {background:rgba(254,154,27,0.1) }
.notices-list .row .col-md-4:nth-child(3n+2) .post_details {background:rgba(63,75,188,0.1)}
.notices-list .row .col-md-4:nth-child(3n+3) .post_details {background:rgba(0,181,119, 0.1)}


.modal-backdrop.show {    opacity: 0.8 !important;}
.modal-backdrop { background-color: #040934 !important;}

// div#postpop .modal-body {
//     border-radius: 35px;
//     padding: 35px;
// }

div#postpop {
    // margin-top: 0;
    max-width: 800px;
}
div#postpop button.close img {
    width: 65px;
}

div#postpop button.close {
    // position: absolute;
    // top: -60px;
    // left: 0;
    // right: 0;
    // margin: auto;
    // width: 60px;
    // cursor: pointer;
    
    opacity: 1;
    position: absolute;
    top: -65px;
    left: 0;
    right: 0;
    z-index: 1100;
    margin: auto;
    display: block;
    float: none;
    cursor: pointer;
}
.postcontentimage img {
    border-radius: 10px;
}
.post-content-image {
    width: 45%; padding-right: 50px;
}
.post-content-content {
    line-height: 25px;
}
.post-content-content-half-width {
    width: 55%;
}