.tenant-dashboard{
    .title-bar {
        border-bottom: 1px solid #DBDBDB;
        padding-bottom: 25px;
    }

    .post_details{border-radius: 15px}
    .post_details.no-image .post__details {
        width: 100%;
    }

    .post__details {min-height: 120px;   padding: 15px 15px 0px;position: relative;}
    .recently-added .post__image {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 15px;
    }
    .post__image img {    border-radius: 15px;}
    .post_time.font-11 {    position: absolute;    bottom: 10px;}
    .recently-added .row .col-md-3:first-child .post_details {background:rgba(244,25,56,0.1) }
    .recently-added .row .col-md-3:nth-child(2) .post_details {background:rgba(0,181,119,0.1)}
    .recently-added .row .col-md-3:nth-child(3) .post_details {background:rgba(0,153,220, 0.1)}
    .recently-added .row .col-md-3:last-child .post_details {background:rgba(254,154,27, 0.1)}

    .rent-collected img {    width: 100%;}
    .rent-collected {    width: 25%;}
    .next-payment {    width: 75%;
        padding-left: 45px;}
    .dashboard-div{
        background: #fff;
        border-radius:15px;
        box-shadow: 0 0 10px rgba(0,0,0,0.05);height: 100%;

    }
    .dashboard-title {
        border-bottom: 1px solid rgba(112,112,112,0.3);
        margin-bottom: 0;
        padding: 15px 25px;
    }
    .dashboard_inner {
        padding: 15px;
    }

    .account-status.d-flex {
        flex-wrap: wrap; padding-left: 25px;
    }
    .account-status.d-flex>div {
        width: 50%;
    }
    .account-status.d-flex .account_status:first-child, .account-status.d-flex .account_status:nth-child(2) {
        color: #3F4BBC; margin-bottom: 35px;
    }

    .account-status.d-flex .account_status:first-child, .account-status.d-flex .account_status:nth-child(3) {
    padding-right: 35px
    }

    .account-status.d-flex .account_status:nth-child(3), .account-status.d-flex .account_status:nth-child(4) {
        color: #00B577;
    }

    .occupency__data{width: 30%}

    .occupency-data .chart {
        width: 70%;
        padding-right: 45px;
    }

    .occupency__status .occupency_status:first-child {
        color: #3F4BBC;
        background: url("../../../assets/images/occu-bg1.svg") no-repeat;
    }
    .occupency__status .occupency_status:last-child {
        color: #F41938;
        background: url("../../../assets/images/occu-bg2.svg") no-repeat;
    }

    .occupency__status .occupency_status{
        background-position: center !important;
        height: 90px;
        background-size: 75% !important;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .occupency-legend.d-flex {
        margin-top: 45px;
    }
    .occupency_legend {
        margin: 0 35px;
    }

    .next-payment .ctas.d-flex {
        margin-top: 35px;
    }

    .next-payment .ctas.d-flex a:first-child {
        margin-right: 15px;
    }
    .start-end div {
        margin-right: 20px;
    }
    .fortnightly-rent {
        margin-right: 0 !important;
    }
    .icon img {
        width: 55px;
    }

    .telephone-div, .wifi-div, .bank-details-div {
        a:hover {
            color: #fff;
        }

        padding: 25px;
        border-radius: 25px;
        box-shadow: 0 0 10px rgba(0,0,0,0.05);
        margin-top: 40px;
        height: 80%;
    }

    .telephone-div {
        width: 40%;
        .left-content{
            width: 150px;

            .avatar__bg {
                left: 60px;

                img {
                    max-width: 110px;
                }
            }
            .avatar_image {
                width: 100px;
                height: 100px;
                top: 0;

                img {
                    // max-width: 100px;
                    border-radius: 15px;
                }
            }
        }
    }

    // .flex-column.justify-content-between {
    //     height: 100%;
    // }

    .wifi-div {
        width: 28%;
        img {
            width: 100px;
        }
    }

    .right-content {
        // margin-left: 45px;
        width: 70%;
        
        .flex-column>div{
            margin-top: 10px;
        }
    }
    .d-flex.contact-details a {
        margin-right: 45px;
    }

    .bank-details-div {
        width: 30%;
    }


    .dashboard-div .issues-row {
        padding: 15px;
        border-bottom: 1px solid rgba(112,112,112,0.4);
        margin-bottom: 0;
        border-radius: 0;
    }
    .dashboard-div .issues-row:last-child {
        border-bottom: none;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
    }


    .account-jist-disabled:after {
        content:"";
        background: rgba(255,255,255,0.45);
        position: absolute;
        width: 110%;
        height: 100%;
        left: -15px;
        top: 0;
    }

    .ctas.mt-3 a:hover {
        color: #fff;
    }

    .issues-row .issue_name {
        width: 15%;
        word-wrap: break-word;
    }
    .issues-row .case-number {
        width: 12%;
    }
    .issues-row .issue-date {
        width: 15%;
    }
    .issues-row .issue-details {
        width: 40%;
        word-wrap: break-word;
    }
    .issues-row .issue-status {
        width: 15%;
    }

    .big-post {
        padding: 1.5em;

        .post-content-image {
            width: 28%;
            padding-right: 30px;
            max-height: 150px;

            .postcontentimage {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    max-width: 100%;
                    max-height: 100%;
                }
            }
        }

        .post-content-content-half-width {
            width: 65%;
        }
    }
}





