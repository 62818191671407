.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}
.dashboard-div{
    background: #fff;
    border-radius:15px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);height: 100%;

}
.resident-rent .dashboard-title {
    border-bottom: 1px solid rgba(112,112,112,0.3);
    margin-bottom: 25px;
    padding: 15px 25px;
}
.dashboard_inner {
    padding: 0 15px 25px;
}

.rent-progress-next {
    padding: 0 25px 15px;
}
.rent-progress {
    width: 75%
}
.next-rent-payment{width: 18%}

.rent_progress {
    width: 100%;
    height: 40px;
    background: #EDEDF8;
    margin-top: 40px;
    border-radius: 45px; position: relative
}

span.progress_width {
    background: #00B577;
    position: absolute;
    display: block;
    height: 100%;
    border-radius: 45px;
}
span.progress_width span.dollar:after {
    content: "$";
    position: absolute;
    font-size: 43px;
    color: #fff;
    right: 0;
    background: #00b577;
    width: 70px;
    height: 70px;
    border-radius: 100%;
    font-family: bold;
    top: -13px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}
.d-flex.payments .payment_div:first-child {
    padding-right: 50px;
}

.next-rent-payment a.curved-btn span {
    font-size: 20px;
    line-height: 18px;
    display: inline-block;
    margin-right: 10px;
}
.prev_transactions,.howtopay {
    padding: 15px 25px;
}
.prev__transactions {
    padding: 10px 0;
}

.card-nos.d-flex .nos {
    background: #3F4BBC;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: inline-block;
    margin: 0 2px;
}

.card-nos.d-flex .nos:nth-child(16),.card-nos.d-flex .nos:nth-child(15),.card-nos.d-flex .nos:nth-child(14),.card-nos.d-flex .nos:nth-child(13){background: none; line-height: 12px}


/* The input-container */
.input-container {
  display: block;
  position: relative;
  padding-left: 65px;
  margin-bottom: 30px;
  cursor: pointer;
  font-size: 16px;
	font-family: bold;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
span.check-label {
    position: relative;
    top: 10px;
}

.input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 40px;
  width: 40px;
  border: 1px solid #BABABA35;
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}


.input-container .checkmark:after {
    left: 11px;
    top: 14px;
    width: 17px;
    height: 15px;
    background: url("../../../assets/images/check-tick.svg") no-repeat;
    background-size: 100%;
}
.modal-backdrop.show {    opacity: 0.8 !important;}
.modal-backdrop { background-color: #040934 !important;}

div#payment .modal-content {
    border-radius: 35px;
    padding: 35px;
}

div#payment .modal-dialog {
    max-width: 600px;
}
div#payment button.close img {
    width: 65px;
}

div#payment button.close {
    opacity: 1;
    position: relative;
    top: -15px;
    z-index: 1100;
    margin: auto;
    display: block;
    float: none; cursor: pointer
}
.payment-options {
    border: 1px solid #BABABA35;
    padding: 10px;
}

div#payment .makepay{border: none; outline: none}

.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}
.transaction {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
	margin-bottom: 25px;
	padding: 10px 25px;
}
.transaction-headings {
	padding: 0 25px
}



.invoice-number{width: 18% }
.invoice-date{width:14% }
.invoice-duedate{width:14%}
.invoice-paid{width:20% }
.invoice-due{width:20% }
.invoice-invoice{width:14% }

.invoice-invoice img {
    margin-left: 15px;
}




.resident-rent {
    position: relative;
}
.resident-rent:after {
    content: "";
    background: rgba(255,255,255,0.45);
    position: absolute;
    width: 110%;
    height: 100%;
    left: -15px;
    top: 0;
}