.login{background: #031083 !important}
.left-login-image {
    width: 30%;
    padding-right: 65px;
}
.right-login-container {
    width: 55%;
	padding-left: 65px
}

.right-login-container {
    width: 55%;
    padding-left: 65px;
    border-left: 1px solid rgba(255,255,255,0.15);
}
.fields{
    margin-bottom: 20px;
}
.fields input {
    width: 100%;
    height: 70px;
    // margin-bottom: 20px;
    border-radius: 45px !important;
    background: rgba(255,255,255,0.14);
    border: none;
    padding-left: 30px;
    line-height: 70px; color: #fff
}

.fields input[type="submit"] {
    background: #F41938;
    color: #fff;
    font-family: bold;
    font-size: 24px; cursor: pointer; padding-left: 0
}
.login-form input::-webkit-input-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.login-form input:-moz-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.login-form input::-moz-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.login-form input:-ms-input-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.login-form a:hover {
    color: #fff;
}

.absolute-images img{
 position: absolute
}
img.girl {
    width: 20%;
    bottom: -90px;
    right: 15%;
}
img.man {
    width: 20%;
    top: 10%;
    right: -3%;
}
img.squiggly {
    width: 15%;
    top: -50px;
    right: 25%;
}

a.backto {
    position: absolute;
    top: 15%;
}

.top-logo img{width: 165px}
.new-password form{display: flex}
.new-password form .fields:not(:last-child){margin-right: 25px}
.new-password form .fields{width: 33.33%;}
.top-logo {    position: absolute;    padding-top: 5%;}
.forgot__container,.bottom-form{width: 100%}
.bottom-form input::-webkit-input-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.bottom-form input:-moz-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.bottom-form input::-moz-placeholder{font-family: regular; color: #fff; font-size: 16px;}
.bottom-form input:-ms-input-placeholder{font-family: regular; color: #fff; font-size: 16px;}
