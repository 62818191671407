.admin-wifi {
    margin-top: 40px;

    .curved-btn {
        width: 150px;
        height: 50px;
        margin-right: 20px;
    }
    .curved-btn.no-border {
        border: none;
    }
    .curved-btn.cancel {
        background-color: #fff;
    }

    .lp-fields {
        margin-bottom: 25px;
    }
    .lp-fields input[type="text"] {
        margin-bottom: 10px;
    }

    h6 {
        font-size: 1.2rem;
        border-bottom: 1px solid #DBDBDB;
        margin-bottom: 10px;
        margin-left: 20px;
        padding-left: 0;
    }
}