.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.search-residents .search-field {
    background: #FE9A1B;
	border-radius: 35px;
	margin-right: 15px
}

.search-residents .search-field input[type="search"]::-webkit-input-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]:-moz-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]::-moz-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]:-ms-input-placeholder{color:#fff; font-size: 15px;}


.resident__image img{ width: 100%;height:100%;}

.resident_phone-email a{display: block}
.search-div.search-residents {
    border: none;
    height: auto;
    padding: 0;    margin-right: 0;
}
.resident {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
	margin-bottom: 25px
}

.resident>div:last-child{
    padding-right: 0;
}
.resident>div:first-child{
    padding-left: 0;
}

// .title-bar .font-45 a {
//     display: inline-block;
//     margin-right: 10px;
//     position: relative;
//     top: -4px;
// }



/*****************Single Resident ****************************/


.avatar_image{
    width: 170px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;top: 30px; border-radius: 25px
}
.avatar_image img {
    width: 100%; border-radius: 25px
}

.avatar__bg {
    left: 30px;
}
.resident-details.d-flex>div {
    width: 33.33%;
    padding: 25px;
    overflow-wrap: anywhere;
}

.resident-details.d-flex {
    flex-wrap: wrap;
	padding-left: 80px
}
.all-invoices a {
    background: #0099DC;
}
.invoices>div, .all-invoices>div {
	margin-bottom: 30px;}

.collection-lease-data>div {
    background: #fff;
    border-radius: 35px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
}

.collection-lease-data .collection-data{width: 35%; margin-right: 30px}
.collection-data-disabled{
    position:relative;
}
.collection-data-disabled:after{
    content: "";
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    border-radius: 35px;
}
.collection-lease-data .lease-data{width: 48%; margin-right: 30px;}
// .collection-lease-data .emergency-contact-data{width: 35%;}

.collection-lease-title {
    border-bottom: 1px solid rgba(112,112,112,0.3);
    margin-bottom: 25px;
    padding: 25px;
}
.collection__data, .lease__data{padding: 0 25px}
.lease-data button {
    background: #D988D3;
    margin-left: 25px;
    margin-top: 30px;
    margin-bottom: 25px;
    border: none;
}
.tenant-documents.pt-40 {
    border-top: 1px solid #DBDBDB;
    margin-top: 40px;
}
.documents.d-flex {
    flex-wrap: wrap;
}
.new-tenant-form .lp-fields select {	background-image: url("../../../assets/images/select-arrow.svg") !important;}
.lp-fields {
    position: relative;
}
.lp-fields textarea {
    height: 160px;
}
.lp-fields input[type="submit"] {
    background: #F41938;
    color: #fff;
    font-family: bold;
    font-size: 18px; cursor: pointer; padding-left: 0;
	width: 250px
}
span.calendar-icon {
    position: absolute;
    right: 30px;
    top: 15px;
}
::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar.svg") no-repeat; width: 25px; height: 25px
}
.new-tenant-form .lp-fields {
    margin-bottom: 25px;
}
.file-chooser__input {
    cursor: pointer;
    height: 100%;
    position: absolute;
    filter: alpha(opacity=1);
    -moz-opacity: 0;
    opacity: 0;
    width: 145px;
    padding: 0;    top: 0;
    left: 0;
}
.file-uploader {
  background-color: #dbefe9;
  border-radius: 3px;
  color: #242424;
}

.file-uploader__message-area {
  font-size: 0;
  padding: 0;
}

.file-list {
  font-size: 16px;    display: flex;
}

.file-list__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-list li {
    height: 30px;
    line-height: 30px;
    margin-left: .5em;
    border: none;
    overflow: hidden;
    font-size: 13px;
}

.new-tenant-form .removal-button {
  width: 20px;
  border: none;
  background:url("../../../assets/images/popup-close.svg"); background-size: 100%; background-repeat:  no-repeat
}

.removal-button:focus {
  outline: 0;
}

.file-chooser {
  transition: background-color 1s, height 1s;
}
.file-chooser  p {
  font-size: 18px;
  padding-top: 1em;
}

.file-uploader {
  max-width: 400px;
  height: auto;
  margin: 2em auto;
}
.file-uploader * {
  display: block;
}
.file-uploader input[type=submit] {
  margin-top: 2em;
  float: right;
}

.file-list__name {
  max-width: 70%;
  float: left;
}

.new-tenant-form .removal-button {
  display: inline-block;
  height: 100%;
  float: right;cursor: pointer;
}

.file-chooser__input {
  margin: 0 auto;
}

.file-uploader__submit-button {
  width: 100%;
  border: none;
  font-size: 1.5em;
  padding: 1em;
  background-color: #72bfa7;
  color: white;
}
.file-uploader__submit-button:hover {
  background-color: #a7d7c8;
}

.file-list li:after, .file-uploader:after {
  content: "";
  display: table;
  clear: both;
}

.hidden {
  display: none;
}
.hidden input {
  display: none;
}

.input-flex{flex-direction: row-reverse;align-items: flex-start;    justify-content: flex-end;}

.new-tenant-form button{border: 1px solid #F41938; margin-right: 30px; padding: 0; width: 130px; height: 50px; line-height: 50px}
.new-tenant-form img.man {
width: 13%;
    bottom: 40%;
    right: -3%; position: absolute
}
.new-tenant-form img.girl {
    width: 15%;
    bottom: -5%;
    right: 0;position: absolute
}

.add-tenant {
    height: 100%;
}

.submitted-message{padding: 200px 0}



/* edit tentant and changes */

.new-tenant-form .lp-fields input, .new-tenant-form .lp-fields textarea ,.new-tenant-form .lp-fields select {
    width: 100%;
    height: 60px;
    margin-bottom: 10px;
    border-radius: 45px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 60px;
    color: #000;
}
.documents.d-flex .document-div {
    width: 23%;
    background: #fff;
    padding: 15px;
    border-radius: 20px;
    margin:15px 15px 15px 0px;
    height: 70px;
}
.edit-tenant .documents.d-flex .document-div {
    width: 33%;
    padding: 1% 6% 1% 1%;
}

.document-icon img {
    width: 45px;
}

.edit-tenant .file-chooser span.btn_upload img {
    height: 80px;
width: auto;
}
.edit-tenant .file-chooser__input {
    z-index: 1;
}

.lease-details-page .row .col-md-4 .tenantlease{
    margin-bottom: 45px;
    padding-bottom: 45px;
    border-bottom: 1px solid #DBDBDB;
}

.new-tenant-form.lease-details-page img.girl {
    width: 18%;
    bottom: -15%;
}




/************ 19-06-20*******************/

ul.customselect { 
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    border-radius: 45px !important;
    background-color: rgba(46,57,152,0.14);
    border: none;
    padding-left: 20px;
    line-height: 60px;
    color: #000;background-image: url("../../../assets/images/select-arrow.svg"); background-repeat: no-repeat; background-position: 95%;
}
ul.customselect li { height: 60px;line-height: 60px; z-index: 2; position: relative}
ul.customselect li:not(.init) {
    width: 95%;
    display: none;
    background: #F93549;
    color: #fff;
    text-align: center; line-height: 50px; height: 50px;
}
ul.customselect.show li {
    display: list-item;
}
ul.customselect li:not(.init):hover, ul li.selected:not(.init) { background: #031083; }
ul.customselect li.init { cursor: pointer; }
.add-tenant ul.customselect{
    margin-bottom: 10px;
}



/************** changes 01-07-20 **************/


.resident__image {
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 90px;
    height: 90px;
}
.resident .resident_enter {
    width: 10%;
}

.resident .resident_phone-email {
    width: 25%;
    overflow-wrap: anywhere;
}
.resident .resident_start {
    width: 10%;
}
.resident .resident_end {
    width: 10%;
}
.resident .resident_room {
    width: 8%;
}
.resident .resident_rent {
    width: 18%;
}
.resident .resident_status {
    width: 10%;
}
.resident .resident_image{width: 12%}

.resident>div {
    padding: 0 10px; width: 15%
}


/******************* changes 02-07-20 **********************/

// .search-residents .search-field input[type="submit"] {
//     left: 15px;
//     background: url("../../../assets/images/white-Search.svg") no-repeat left center;
//     top: 6px;    background-size: 100% !important;
// }

.search-residents .search-field input[type="search"] {
    color: #fff;
    padding: 7px 0px 7px 55px;
    line-height: 30px;
}

// .create-post a span {
//     font-size: 20px;
//     line-height: 18px;
//     display: inline-block;
//     margin-right: 10px;
// }

.file-chooser span.btn_upload img {
    width: auto;
    height: 80px;
}








.add-tenant input::placeholder {
    color: black;
}

.document-div input#removeImage1 {
    width: 42px;
    color: transparent;
    background: url('../../../assets/images/popup-close.svg');
    height: 42px;
    padding: 0;
    background-size: 100%;
    position: absolute;
    right: 2%;
    top: 14px;
    border: none;
}

.create-post a span {
    font-size: 21px;
    line-height: 18px;
    display: inline-block;
    margin-right: 10px;
}

.search-residents .search-field input[type=submit] {
    left: 15px;
    background: url("../../../assets/images/white-Search.svg") no-repeat 0;
    top: 11px;
    background-size: 100%!important;
}

.admin-resident-detail.title-bar .font-45 a {
    display: inline-flex;
    margin-right: 10px;
    position: initial;
}

.label-currency {
    position: absolute;
    font-size: 16px;
    padding: 18px;
    background-color: rgba(46,57,152,0.2);
    border-top-left-radius: 45px;
    border-bottom-left-radius: 45px;
}

.new-tenant-form {
    h6 {
        font-size: 1.5rem;
        border-bottom: 1px solid #DBDBDB;
        margin-bottom: 20px;
        margin-left: 15px;
        padding-left: 0;
    }

    .file-chooser {
        margin-bottom: 0;
        position: relative;
    }

    ul.autocomplete-suggestions{
        z-index: 1;
        position: absolute;
        background: #F93549;
        color: white;
        margin-top: -20px;
        list-style: none;
        padding: 0;

        li {
            padding: 0 20px;
        }
        
        li:hover {
            background: #031083;
        }
    }

    .radio-container {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .radio-container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .radio-checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 24px;
        width: 24px;
        background-color: #eee;
        border-radius: 50%;
        border: #031083 1px solid;
    }

    .radio-container:hover input ~ .radio-checkmark {
        background-color: #ccc;
    }

    .radio-container input:checked ~ .radio-checkmark {
        background-color: #fff;
    }

    .radio-checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .radio-container input:checked ~ .radio-checkmark:after {
        display: block;
    }

    .radio-container .radio-checkmark:after {
        top: 3px;
        left: 3px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: #031083;
    }
}

.resident-filter {
    width: 250px;
    height: 50px;
    padding: 0 20px;
    
    ul.customselect {
        height: 50px;
    }

    ul.customselect li.init {
        width: 100%;
        text-align: left;
        line-height: 50px;
        height: 50px;
        cursor: pointer;
    }

    ul.customselect li:not(.init) {
        display: none;
        padding-left: 15px;
        left: -10px;
        top: 10px;
        z-index: 3;
    }

    ul.customselect.show li:not(.init) {
        display: block;
        background-color: #FE9A1B;
        color: #fff;
        text-align: left;
    }
    ul.customselect.show li:not(.init):hover {
        background: #031083;
        color: #fff;
    }

    ul.customselect li:last-child {
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }
    ul.customselect li:nth-child(2) {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }
}