.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.post_details{border-radius: 15px}
.post_details.no-image .post__details {
    width: 100%;
}

.post__details {
       min-height: 150px;
    padding: 20px 35px 40px; position: relative;}
.post-image {
    display: flex;
    align-items: center;
}
.post__image {
    width: 150px;
}
.post__image img {
    border-radius: 15px;
    height: 100%;
}
.post_time.font-11 {
    position: absolute;
    bottom: 20px;
    min-width: 100px;
}
.this-months-list .row .col-md-4:first-child .post_details {background:rgba(244,25,56,0.1) }
.this-months-list .row .col-md-4:nth-child(2) .post_details {background:rgba(0,181,119,0.1)}
.this-months-list .row .col-md-4:last-child .post_details {background:rgba(0,153,220, 0.1)}

// .this-months-list .row .col-md-4,
.prev-months-list .row .col-md-4{margin-bottom: 30px}

.prev-months-list .row .col-md-4:nth-child(3n+1) .post_details {background:rgba(244,25,56,0.1) }
.prev-months-list .row .col-md-4:nth-child(3n+2) .post_details {background:rgba(0,181,119,0.1)}
.prev-months-list .row .col-md-4:nth-child(3n+3) .post_details {background:rgba(0,153,220, 0.1)}


/************************* Previous Post page 05-06-20 **************************************/



.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}

/***************************************************************/

/************************* Post page popup 05-06-20 **************************************/



div#prevposts .modal-content {
    border-radius: 35px;
    padding: 45px 45px 0;
}

.modal-backdrop { background-color: #040934 !important;}
.modal-backdrop.show {    opacity: .88 !important;}

input::-webkit-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}
input:-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
input::-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
input:-ms-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea::-webkit-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea:-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea::-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea:-ms-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}

.noticeboard.fields input[type="submit"] {
    background: #F41938;
    color: #fff;
    font-family: bold;
    font-size: 24px;
    cursor: pointer;
    padding-left: 0;
}



/*preview image style 08-06-20 */

.btn_upload {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
    color: #031083;
    margin-bottom: 20px;
    outline: none;
    vertical-align: middle;
	font-family: bold; text-decoration: underline
}

.btn_upload input {
  cursor: pointer;
  height: 100%;
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;    width: 100%;
    padding: 0;
}

.it {
  width: 250px;
  margin-left: 10px;    border-radius: 25px;
}
.noticeboard.fields input#removeImage1 {
    width: 42px;
    color: transparent;
    background: url('../../assets/images/popup-close.svg');
    height: 42px;
    padding: 0;
    background-size: 100%;
    position: absolute;
    left: 110px;
    top: 5px;
}

.btn-rmv1{
//   display: none;
}

.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  display: inline-block;
  background: rgba(255, 0, 0, 1);
  margin: -5px -10px;
}

.preview1.it {
    display: block;margin: 20px 0;
}

.preview1 {
    width: 250px;
  margin-left: 10px;    border-radius: 25px;
    // display: none;
}



/* Save changes */



.noticeboard.fields input.delete-post {
    padding: 0;
    width: 250px;
    background: transparent;
    border: 1px solid #F41938;
    color: #F41938;
    margin-left: 15px;
    font-family: bold;
}

/* Delete changes */


div#prevposts .modal-dialog.delete-posts {
    max-width: 830px;
}

.modal-dialog.delete-posts .post_details.d-flex {
    background: rgba(3,16,131, 0.1);
    width: 580px;
    margin: auto;
}

.delete-ctas button {
    width: 250px;
    text-align: center;
    font-family: bold;
    font-size: 24px;
    display: inline-block;
    margin: 0 10px;
}
.delete-ctas button.curved-btn.red-border {
    background: #fff;
}
.delete-ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}
.curved-btn.red-bg {
    color: #fff;
    border: 1px solid #F41938;
}
.curved-btn.red-border {
    border: 1px solid #F41938;
    color: #F41938;
}



/* changes */

div#prevposts .modal-dialog {
	max-width:850px;    margin:80px auto 0;}
.popup-man img {
    width:65%;
}
.popup-man {
    right: -170px;
    top: 60px;
}

span.modal-close {
    position: absolute;
    top: -60px;
    left: 0;
    right: 0;
    margin: auto;
    width: 60px;
    cursor: pointer;
}
span.modal-close img {
    width: 60px;
}
.noticeboard.fields input, .noticeboard.fields textarea {
    width: 75%;
    height: 60px;
    margin-bottom: 20px;
    border-radius: 45px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 60px;
    color: #031083;
}

.noticeboard.fields textarea {
    height: 160px;
    line-height: 25px;
    padding: 30px;
}
.noticeboard.fields input[type="submit"] {
    background: #F41938;
    color: #fff;
    font-family: bold;
    font-size: 18px; cursor: pointer; padding-left: 0;
	width: 250px
}

/******************* changes 02-07-20 **********************/

// .create-post a span {
//     font-size: 18px;
//     line-height: 18px;
//     display: inline-block;
//     margin-right: 10px;
// }

.delete-post.post_details {
    background-color: rgba(3,16,131,0.07);
}

.create-post a span {
    font-size: 21px;
    line-height: 18px;
    display: inline-block;
    margin-right: 10px;
}