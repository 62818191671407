.profile .title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 0;
    align-items: center;
}
.profile-details .col-md-4, .profile-details .col-md-6 {
    margin-top: 45px;
}

.profile-details .lp-fields  .btn_upload {
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  position: relative;
    color: #031083;
    margin-left: 30px;
    outline: none;
    vertical-align: middle;
	font-family: bold; text-decoration: underline
}

.profile-details .lp-fields  .btn_upload input {
  cursor: pointer;
  height: 100%;
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;    width: 100%;
    padding: 0;
}

.profile-details .it {
  width: 120px; height: 120px;
  margin-left: 10px;    border-radius: 100%;
}
.profile-details .lp-fields input#removeImage1 {
    width: 42px;
    color: transparent;
    background: url("../../../assets/images/popup-close.svg");
    height: 42px;
    padding: 0;
    background-size: 100%; margin-left: 30px
}

.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  display: inline-block;
  background: rgba(255, 0, 0, 1);
  margin: -5px -10px;
}

.profile-details .preview1.it {
    display: block;margin: 20px 0;
}

.curved-btn.red-bg {
    color: #fff;
}
.curved-btn.red-border {
    border: 1px solid #F41938;
    color: #F41938;
}

.profile-details .lp-fields input, .edit-password .lp-fields input{
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    border-radius: 45px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 45px;
    color: #031083;
}
// span.edit-password {
//     position: absolute;
//     top: 0;
//     right: 0;
//     width: 200px;
//     border-radius: 25px;
//     height: 45px;
//     text-align: center;
//     line-height: 45px;
// }

 .profile-details .d-flex button, .edit-password .d-flex button{border: 1px solid #F41938; margin-right: 30px; padding: 0; width: 130px; height: 50px; line-height: 50px}
 .profile-details .d-flex button.cancel, .edit-password button.cancel{background-color: transparent}
 .logout-btn{background-color: #fff;border: 1px solid #F41938;margin: 10px;}
 .edit-password .col-md-4 {margin-top: 20px;}
 .profile a.curved-btn:hover {
   color: #fff;
 }
