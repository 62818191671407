
.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}

.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}
input::-webkit-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}
input:-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
input::-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
input:-ms-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea::-webkit-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea:-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea::-moz-placeholder{font-family: regular; color: #031083; font-size: 16px;}
textarea:-ms-input-placeholder{font-family: regular; color: #031083; font-size: 16px;}

.lp-fields input[type="submit"] {
    background: #F41938;
    color: #fff;
    font-family: bold;
    font-size: 24px;
    cursor: pointer;
    padding-left: 0;
}
.report-issue .btn_upload {
    display: flex;
    flex-wrap: wrap;
    // overflow: hidden;
    // position: relative;
    color: #031083;
    margin-bottom: 20px;
    // outline: none;
    // font-family: bold;
    // text-decoration: underline;
    width: 100%;
    cursor: default;

    input[type="file"] {
        cursor: pointer;
        width: 150px;
        height: 150px;

        position: relative;
        left: -150px;
    }
}

.lp-fields .btn_upload input {
  cursor: pointer;
  height: 100%;
  position: absolute;
  filter: alpha(opacity=1);
  -moz-opacity: 0;
  opacity: 0;    width: 100%;
    padding: 0;
}

.it {
  width: 250px;
  margin-left: 10px;    border-radius: 25px;
}
.report-issue .lp-fields button#removeImage1 {
    width: 42px;
    color: transparent;
    background: url("../../../assets/images/popup-close.svg");
    height: 42px;
    padding: 0;
    background-size: 100%;
    position: relative;
    left: -47px;
    top: 5px;
    z-index: 2;
    border: none;
}
.report-issue img.upload-btn {
    height: 135px;
}

.btn-rmv1{
//   display: none;
}

.lp-fields input#removeImage1.btn-rmv1.rmv{opacity: 1}

.rmv {
  cursor: pointer;
  color: #fff;
  border-radius: 30px;
  border: 1px solid #fff;
  display: inline-block;
  background: rgba(255, 0, 0, 1);
  margin: -5px -10px;
}

.report-issue .preview1.it {
    display: block;
    margin: 0;
    max-height: 135px;
    width: auto;
}

.lp-fields input.delete-post {
    background: transparent;
    border: 1px solid #F41938;
    color: #F41938;
    margin-left: 15px;
}
.delete-ctas a {
    width: 250px;
    text-align: center;
    font-family: bold;
    font-size: 24px;
    display: inline-block;
    margin: 0 10px;
}
.delete-ctas {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 40px 0;
}
.curved-btn.red-bg {
    color: #fff;
}
.curved-btn.red-border {
    border: 1px solid #F41938;
    color: #F41938;
}

.report-issue .lp-fields input[type="submit"] {
    background: #F41938;
    color: #fff;
    font-family: bold;
    font-size: 18px; cursor: pointer; padding-left: 0;
    width: 120px;
    height: 50px;
    margin-bottom: 20px;
    border-radius: 45px !important;
    border: none;
    line-height: 45px;
}
.report-issue .lp-fields input[type="text"], .lp-fields textarea {
    width: 75%;
    height: 55px;
    margin-bottom: 10px;
    border-radius: 25px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 45px;
    color: #031083;
}
.report-issue .lp-fields {
    margin-bottom: 20px;
}
.report-issue .lp-fields textarea {
    line-height: 20px;
    padding-top: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
}

.lp-fields textarea {
    height: 140px;
}

.my-history img {
    margin-right: 10px;
}

.report-issue .absolute-images img.girl {
    width: 16%;
    right: 20%;
    bottom: -60px;
}
.report-issue .absolute-images img.man {
    width: 20%;
    right: -45px;
    top: initial;
}

.report-issue .absolute-images img {
    position: absolute;
}

.reported-image img {
    width: 75px;
}












// .create-post a span {
//     font-size: 30px;
//     line-height: 18px;
//     display: inline-block;
//     margin-right: 10px;
// }
.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.search-residents .search-field {
    background: #FE9A1B;
	border-radius: 35px;
	margin-right: 15px
}
.search-residents .search-field input[type="search"] {
    color: #fff;
    padding: 7px 0px 7px 55px;
    line-height: 40px;
}


.search-residents .search-field input[type="search"]::-webkit-input-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]:-moz-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]::-moz-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]:-ms-input-placeholder{color:#fff; font-size: 15px;}

.resident__image {
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 130px;
    height: 120px;
}

.search-div.search-residents {
    border: none;
    height: auto;
    padding: 0;    margin-right: 0;
}
.issues-row {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
	margin-bottom: 25px; min-height: 100px	
}

.issues-row>div {
    padding: 0 15px;
}



.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}



.issues-search ::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar.svg") no-repeat; width: 25px; height: 25px
}



.status-issue {
    background: #F41938;
    width: 150px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    border-radius: 35px;
}
.status-issue.pending {
    background: #F41938;
}
.issues-search ul.customselect li:last-child {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.issues-search ul.customselect li:nth-child(2) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}



a.dropdown-toggle{width: 100%}
.dropdown-toggle::after {    top: 17px;    right: 15px; position: absolute}

ul.dropdown-menu {
    color: #fff;
    padding-left: 15px;
    border-radius: 21px;
}

.status-issue.completed {
    background: #00B577;
}

.status-issue.in-progress {
    background: #FE9A1B;
}

ul.dropdown-menu{color: #fff; font-size: 13px; border: none}
.status-issue.pending ul.dropdown-menu{background: #F41938;}
.status-issue.in-progress ul.dropdown-menu{background: #FE9A1B;}
.status-issue.completed ul.dropdown-menu{background: #00B577;}


ul.dropdown-menu li a{font-size: 13px;}

.lp-fields.btn-group a.dropdown-toggle{
    width: 240px;
    height: 50px;
    margin: 0px 10px;
    border-radius: 45px !important;
    background: #E3E3EF;
    border: none;
    padding-left: 30px;
    line-height: 50px;
    color: #000;
}

.lp-fields.btn-group .dropdown-toggle::after{background-image: url("../../../assets/images/select-arrow.svg"); background-repeat: no-repeat; background-position: 95%;    border: none;    width: 15px;    height: 10px;}

.lp-fields.btn-group ul.dropdown-menu {
    width: 100%; color: #000;background:#E3E3EF;
}

.lp-fields.btn-group ul.dropdown-menu li{padding: 5px 0}

.issues-search .lp-fields input, .issues-search .lp-fields textarea, .issues-search .lp-fields select {
    width: 240px;
    height: 50px;
    margin-bottom: 00px;
    border-radius: 45px !important;
    background:#E3E3EF;
    border: none;
    padding-left: 30px;
    line-height: 50px;
    color: #000;
}

.resident-issues.search-residents .search-field input[type=submit] {
    left: 15px;
    background: url("../../../assets/images/white-Search.svg") no-repeat 0;
    top: 11px;
    background-size: 100%!important;
}

.status-issue.pending {
    background: #F41938;
}
.status-issue.in_progress {
    background: #FE9A1B;
}
.status-issue.completed {
    background: #00B577;
}

/*****************changes 20-07-20 **********************/

.report-issue img.upload-btn {
    height: 150px;
    // position: relative;
}

.report-issue .preview1.it {
    display: block;
    margin: 0;
    max-height: 135px;
    width: auto;
    position: absolute;
    opacity: 0.45;
}

img.preview1 {
    max-width: 150px;
    max-height: 150px;
    
}









.resident_issue_list .issue_name {
    width: 15%;
    word-wrap: break-word;
}
.resident_issue_list .case-number {
    width: 12%;
}
.resident_issue_list .issue-date {
    width: 15%;
}
.resident_issue_list .issue-details {
    width: 40%;
    word-wrap: break-word;
}
.issue-details {
    word-wrap: break-word;
}
.resident_issue_list .issue-status {
    width: 15%;
}
.issue-details .col-md-6 .issue__info {
    padding-bottom: 40px;
    // border-bottom: 1px solid #DBDBDB;
    // margin-bottom: 40px;
    word-wrap: break-word;
}



.report-issue .lp-fields {
    button.cancel {
        width: 120px;
        height: 50px;
        margin-bottom: 20px;
        margin-left: 20px;
        border-radius: 45px !important;
        border: none;
        // line-height: 45px;
        border: 1px solid #F41938;
    }
}

.issue_description {
    word-wrap: break-word;
}