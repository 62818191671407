.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.profile-pages .logout-btn {
    margin: 0;
    border: none;
    
    :hover {
        background-color: #F41938;
    }

    img {
        width: 26px;
        margin-right: 10px;
    }
}
.profile-pages a {
        padding: 10px 25px;
}
a.blue.profile-active,.profile-pages a:hover {
    background: #000B8C;
    color: #fff;
    border-radius: 35px;
}

.lp-fields input{
    width: 100%;
    height: 45px;
    margin-bottom: 20px;
    border-radius: 45px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 45px;
    color: #031083;
}
span.edit-password {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    border-radius: 25px;
    height: 45px;
    text-align: center;
    line-height: 45px;
}

.save{
    border: 1px solid #F41938;
    margin-right: 30px;
    padding: 0 !important;
    width: 130px;
    height: 50px;
    line-height: 50px;background: #F41938;    display: table;
    border-radius: 35px !important; color: #fff; font-family: bold;
}




.avatar_image{
    width: 170px;
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;top: 30px; border-radius: 25px
}
.avatar_image img {
    width: 100%; border-radius: 25px
}

.avatar__bg {
    left: 30px;
}
.resident-details.d-flex>div {
    width: 50%;
    padding: 25px;
}

.resident-details.d-flex {
    flex-wrap: wrap;
	padding-left: 80px
}
.all-invoices a {
    background: #0099DC;
}
.invoices>div, .all-invoices>div {
	margin-bottom: 30px;}

// .collection-lease-data>div {
//     background: #fff;
//     border-radius: 35px;
//     box-shadow: 0 0 10px rgba(0,0,0,0.05);
// }

// .collection-lease-data .collection-data{width: 35%; margin-right: 30px}
// .collection-lease-data .lease-data{width: 65%; margin-left: 30px}

// .collection-lease-title {
//     border-bottom: 1px solid rgba(112,112,112,0.3);
//     margin-bottom: 25px;
//     padding: 25px;
// }
// .collection__data, .lease__data{padding: 0 25px}
// .lease-data a {
//     background: #D988D3;
//     margin-left: 25px;
//     margin-top: 30px;
// }
.documents.d-flex {
    flex-wrap: wrap;
}
.new-tenant-form .lp-fields select {	background-image: url("../../../assets/images/select-arrow.svg") !important;}
.lp-fields {
    position: relative;
}
.lp-fields textarea {
    height: 160px;
}
span.calendar-icon {
    position: absolute;
    right: 30px;
    top: 15px;
}
::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar.svg") no-repeat; width: 25px; height: 25px
}
.new-tenant-form .lp-fields {
    margin-bottom: 25px;
}
.file-chooser__input {
    cursor: pointer;
    height: 100%;
    position: absolute;
    filter: alpha(opacity=1);
    -moz-opacity: 0;
    opacity: 0;
    width: 145px;
    padding: 0;    top: 0;
    left: 0;
}
.file-uploader {
  background-color: #dbefe9;
  border-radius: 3px;
  color: #242424;
}

.file-uploader__message-area {
  font-size: 0;
  padding: 0;
}

.file-list {
  font-size: 16px;    display: flex;
}

.file-list__name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-list li {
    height: 30px;
    line-height: 30px;
    margin-left: .5em;
    border: none;
    overflow: hidden;
    font-size: 13px;
}

.new-tenant-form .removal-button {
  width: 20px;
  border: none;
  background:url("../../../assets/images/popup-close.svg"); background-size: 100%; background-repeat:  no-repeat
}

.removal-button:focus {
  outline: 0;
}

.file-chooser {
  transition: background-color 1s, height 1s;
}
.file-chooser  p {
  font-size: 18px;
  padding-top: 1em;
}

.file-uploader {
  max-width: 400px;
  height: auto;
  margin: 2em auto;
}
.file-uploader * {
  display: block;
}
.file-uploader input[type=submit] {
  margin-top: 2em;
  float: right;
}

.file-list__name {
  max-width: 70%;
  float: left;
}

.new-tenant-form .removal-button {
  display: inline-block;
  height: 100%;
  float: right;cursor: pointer;
}

.file-chooser {
margin-bottom: 35px; position: relative
}

.file-chooser__input {
  margin: 0 auto;
}

.file-uploader__submit-button {
  width: 100%;
  border: none;
  font-size: 1.5em;
  padding: 1em;
  background-color: #72bfa7;
  color: white;
}
.file-uploader__submit-button:hover {
  background-color: #a7d7c8;
}

.file-list li:after, .file-uploader:after {
  content: "";
  display: table;
  clear: both;
}

.hidden {
  display: none;
}
.hidden input {
  display: none;
}


.input-flex{flex-direction: row-reverse;align-items: flex-start;    justify-content: flex-end;}

.new-tenant-form button{border: 1px solid #F41938; margin-right: 30px; padding: 0; width: 130px; height: 50px; line-height: 50px}
.new-tenant-form img.man {
width: 13%;
    bottom: 40%;
    right: -3%; position: absolute
}
.new-tenant-form img.girl {
    width: 15%;
    bottom: -5%;
    right: 0;position: absolute
}

.main-content.add-tenant {
    height: 100%;
}

.submitted-message{padding: 200px 0}


.new-tenant-form .lp-fields input, .new-tenant-form .lp-fields textarea ,.new-tenant-form .lp-fields select {
    width: 100%;
    height: 60px;
    margin-bottom: 20px;
    border-radius: 45px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 60px;
    color: #000;
}
.documents.d-flex .document-div {
    width: 23%;
    background: #fff;
    padding: 15px;
    border-radius: 20px;
    margin:15px 15px 15px 0px;
    height: 70px;
}

.document-icon img {
    width: 45px;
}

.main-content.edit-tenant .file-chooser span.btn_upload img {
    height: 80px;
width: auto;
}
.main-content.edit-tenant .file-chooser__input {
    z-index: 1;
}

.lease-details-page .row .col-md-4 .tenantlease{
    margin-bottom: 45px;
    padding-bottom: 45px;
    border-bottom: 1px solid #DBDBDB;
}

.new-tenant-form.lease-details-page img.girl {
    width: 18%;
    bottom: -15%;
}

// .my-lease-data {
//     padding: 50px 0;
//     margin: 50px 0;
//     border-top: 1px solid #DBDBDB;
//     border-bottom: 1px solid #DBDBDB;
// }




/*****************changes 11-07-20 **********************/

.card-nos.d-flex .nos {
    background: #3F4BBC;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    display: inline-block;
    margin: 0 2px;
}

.card-nos.d-flex .nos:nth-child(16),.card-nos.d-flex .nos:nth-child(15),.card-nos.d-flex .nos:nth-child(14),.card-nos.d-flex .nos:nth-child(13){background: none; line-height: 12px}


.card-details {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    margin-bottom: 25px;
}
.card-image {
    padding: 15px;
}

.delete-card img, .edit-card img {
    width: 55px;
}

.edit-delete{padding-right: 15px}

.input-container {
    display: flex;
    position: relative;
    margin: 0 auto;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    align-items: center;
    justify-content: center;height: 100%;
}

/* Hide the browser's default radio button */
.input-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}
.input-container:hover input ~ .checkmark {
  border: 4px solid #2196F3;
}
.input-container input:checked ~ .checkmark {
    border: 4px solid #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.input-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.input-container .checkmark:after {
 	top: 9px;
	left: 9px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: white;
}
div#addpayment button.close img {
    width: 45px;
    margin: auto;
    display: block;
}

div#addpayment button.close {
    opacity: 1;
    width: 45px;
    margin: auto;
    text-shadow: none;
    position: absolute;
    top: -50px;
    left: 0;
    right: 0;
}

div#addpayment .modal-dialog {
    max-width: 600px;
    margin: 5% auto;
}

input.year {
    position: absolute;
    right: 0;
    width: 60%;
}

.lp-fields.card-expiry {
    position: relative;
}



/*****************changes 20-07-20 **********************/



.my-lease-data {
    padding-top: 30px;
    border-top: 1px solid #DBDBDB;
    margin-bottom: 20px;
}

.tenant-payment-details {
    position: relative;
	padding: 50px 0 0;
	margin: 20px 0;    border-top: 1px solid #DBDBDB;
}
.tenant-payment-details:after {
    content: "";
    background: rgba(255,255,255,0.45);
    position: absolute;
    width: 110%;
    height: 100%;
    left: -15px;
    top: 0;
}



.lease_detail {
    margin-right: 90px;
}
.note {
    background: #031083;
    border-radius: 25px;
    text-align: center;
    padding: 30px;
    margin: 30px 0;

    .note-details {
        color: #fff;
    }
    .note-link {
        color: #FE9A1B;
        font-size: 24px;
    }
}

.resident-profile {
    .resident-photo {
        width: 20%;
    }
    .resident-name {
        font-family: "d-bold";
        color: #000b8c;
        font-size: 30px;
        width: 20%;
        display: flex;
        padding-top: 45px;
    }
    .resident__details {
        width: 50%;
    }
    .resident-details.d-flex {
        padding-left: 20px;
    }
}









.pink-bg {
    background: #D988D3;
}
.purple-bg {
    background: #3F4BBC;
}
.resident-information .pink-bg, .tenant-payment-details .purple-bg:hover{
    color:#fff;
}

.edit-resident-profile-details {
    .lp-fields {
        margin-bottom: 20px;

        input {
            width: 100%;
            height: 60px;
            margin-bottom: 10px;
            border-radius: 45px !important;
            background: rgba(46, 57, 152, 0.14);
            border: none;
            padding-left: 30px;
            line-height: 45px;
            color: #031083;
        }
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    button {
        width: 130px;
        margin-right: 30px;
        height: 50px;
        border: 1px solid #F41938;
    }
    button.cancel {
        background-color: #fff;
    }


    .profile-details .lp-fields  .btn_upload {
        cursor: pointer;
        display: inline-block;
        overflow: hidden;
        position: relative;
        color: #031083;
        margin-left: 30px;
        outline: none;
        vertical-align: middle;
        font-family: bold; text-decoration: underline
    }

    .profile-details .lp-fields  .btn_upload input {
        cursor: pointer;
        height: 100%;
        position: absolute;
        filter: alpha(opacity=1);
        -moz-opacity: 0;
        opacity: 0;    width: 100%;
        padding: 0;
    }

    .profile-details .it {
        width: 120px; height: 120px;
        margin-left: 10px;    border-radius: 100%;
    }
    .profile-details .lp-fields input#removeImage1 {
        width: 42px;
        color: transparent;
        background: url("../../../assets/images/popup-close.svg");
        height: 42px;
        padding: 0;
        background-size: 100%; margin-left: 30px
    }

    .rmv {
        cursor: pointer;
        color: #fff;
        border-radius: 30px;
        border: 1px solid #fff;
        display: inline-block;
        background: rgba(255, 0, 0, 1);
        margin: -5px -10px;
    }

    .profile-details .preview1.it {
        display: block;margin: 20px 0;
    }

    h6 {
        font-size: 1.5rem;
        border-bottom: 1px solid #DBDBDB;
        margin-bottom: 20px;
        margin-top: 15px;
        padding-left: 0;
    }
}