.admin-dashboard {
    // .create-post a span {
    //     font-size: 30px;
    //     line-height: 18px;
    //     display: inline-block;
    //     margin-right: 10px;
    // }
    .title-bar {
        border-bottom: 1px solid #DBDBDB;
        padding-bottom: 25px;
    }

    .post_details{border-radius: 15px}
    .post_details.no-image .post__details {
        width: 100%;
    }

    .post__details {min-height: 120px;   padding: 15px 15px 0px;position: relative;min-width: 65%;}
    .recently-added .post__image {
        width: 120px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        border-radius: 15px;
    }
    .post__image img {    border-radius: 15px;}
    .post_time.font-11 {    position: absolute;    bottom: 10px;}
    .recently-added .row .col-md-3:first-child .post_details {background:rgba(244,25,56,0.1) }
    .recently-added .row .col-md-3:nth-child(2) .post_details {background:rgba(0,181,119,0.1)}
    .recently-added .row .col-md-3:nth-child(3) .post_details {background:rgba(0,153,220, 0.1)}
    .recently-added .row .col-md-3:last-child .post_details {background:rgba(254,154,27, 0.1)}

    .chart .recharts-wrapper {    width: 100%;}
    .chart {    width: 45%;}
    .chart .percentage-amount {
        font-size: 3rem;
        font-weight: bold;
    }
    .chart .percentage-text {
        font-size: 0.7rem;
    }
    .tickets {    width: 50%;}
    .ticket-status.d-flex {    flex-wrap: wrap;}
    .ticket-status .ticket_status{    background-size: 110px;
        cursor: pointer;
        background-repeat: no-repeat;
        width: 43%;
        height: 105px; display: flex; flex-direction: column; align-items: center; justify-content: center; background-position: center}

    .ticket-status .ticket_status:nth-child(1){ background-image: url("../../../assets/images/ticket-blue.svg")}
    .ticket-status .ticket_status:nth-child(2){ background-image: url("../../../assets/images/ticket-orange.svg")}
    .ticket-status .ticket_status:nth-child(3){ background-image: url("../../../assets/images/ticket-lblue.svg")}
    .ticket-status .ticket_status:nth-child(4){ background-image: url("../../../assets/images/ticket-pink.svg")}
    .ticket-status .ticket_status .bold {
        margin-bottom: 5px;
    }

    .dashboard-jist ul.customselect { 
        width: 105px; margin-bottom: 0;
        border: none;
        padding-left: 20px;
        color: #000;background-image: url("../../../assets/images/toggle-arrow.svg"); background-repeat: no-repeat; background-position: 95%; font-size: 13px; font-family: light
    }
    .dashboard-jist ul.customselect li {z-index: 2; position: relative}
    .dashboard-jist ul.customselect li:not(.init) {
        width: 100%;
        display: none;
        color: #fff;
        text-align: center; 
    }
    .dashboard-jist ul.customselect li:not(.init):hover, ul li.selected:not(.init) {}
    .dashboard-jist ul.customselect li.init { cursor: pointer; }


    .dashboard-div{
        background: #fff;
        border-radius: 35px;
        box-shadow: 0 0 10px rgba(0,0,0,0.05);height: 100%;

    }
    .dashboard-title {
        border-bottom: 1px solid rgba(112,112,112,0.3);
        // margin-bottom: 15px;
        padding: 15px 25px;
    }
    .chart-tickets {
        padding: 15px;
    }

    .account-jist {
        opacity: 0.5;
    }
    .account-status.d-flex {
        flex-wrap: wrap; padding-left: 25px;
    }
    .account-status.d-flex>div {
        width: 50%;
    }
    .account-status.d-flex .account_status:first-child, .account-status.d-flex .account_status:nth-child(2) {
        color: #3F4BBC; margin-bottom: 35px;
    }

    .account-status.d-flex .account_status:first-child, .account-status.d-flex .account_status:nth-child(3) {
    padding-right: 35px
    }

    .account-status.d-flex .account_status:nth-child(3), .account-status.d-flex .account_status:nth-child(4) {
        color: #00B577;
    }

    .occupency {
        opacity: 0.5;
    }
    
    .occupency__data{width: 30%}

    .occupency-data .chart {
        width: 70%;
        padding-right: 45px;

        img {
            width: 100%;
        }
    }

    .occupency__status .occupency_status:first-child {
        color: #3F4BBC;
        background: url("../../../assets/images/occu-bg1.svg") no-repeat;
    }
    .occupency__status .occupency_status:last-child {
        color: #F41938;
        background: url("../../../assets/images/occu-bg2.svg") no-repeat;
    }

    .occupency__status .occupency_status{
        background-position: center !important;
        height: 90px;
        background-size: 75% !important;
        width: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .occupency-legend.d-flex {
        margin-top: 45px;
    }
    .occupency_legend {
        margin: 0 35px;
    }



// recent residents sections styles from buildingManagerDashboard
    .recent-residents .resident__image {
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        width: 65px;
        height: 65px;
    }
    .recent-residents .resident__image img{width: 100%;height:100%;}

    .recent-residents  .resident {
        background: none;
        border-radius: 0;
        box-shadow: none;
        margin-bottom: 25px; font-size: 14px !important;
    }

    .resident>div {
        padding: 0 15px !important;
        width: 18%;
    }

    .recent-residents .resident_name{width: 15%}

    .resident>div .font-20{font-size: 14px}

    .resident .resident_phone-email {
        width: 28%;
    }
    .recent-residents .resident .resident_room {
        width: 11%;
    }
    .resident .resident_image{width: 12%}
    .height-230 {
        height: 230px;
    }

}