// .create-post a span {
//     font-size: 30px;
//     line-height: 18px;
//     display: inline-block;
//     margin-right: 10px;
// }
.title-bar {
    border-bottom: 1px solid #DBDBDB;
    padding-bottom: 25px;
}

.search-residents .search-field {
    background: #FE9A1B;
	border-radius: 35px;
	margin-right: 15px
}
.search-residents .search-field input[type="search"] {
    color: #fff;
    padding: 7px 10px 7px 55px;
    line-height: 40px;
}

.search-residents .search-field input[type="search"]::-webkit-input-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]:-moz-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]::-moz-placeholder{color:#fff; font-size: 15px;}
.search-residents .search-field input[type="search"]:-ms-input-placeholder{color:#fff; font-size: 15px;}

.resident__image {
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    width: 130px;
    height: 120px;
}

.search-div.search-residents {
    border: none;
    height: auto;
    padding: 0;    margin-right: 0;
}
.issues-row {
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
	margin-bottom: 25px; min-height: 100px	
}

.issues-row>div {
    padding: 0 15px;
}



.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}

.issues-search .lp-fields input, .issues-search .lp-fields textarea, .issues-search .lp-fields select {
    width: 240px;
    height: 50px;
    margin-bottom: 00px;
    border-radius: 45px !important;
    background: rgba(46,57,152,0.14);
    border: none;
    padding-left: 30px;
    line-height: 50px;
    color: #000;
}

.issues-search ::-webkit-calendar-picker-indicator {
  background: url("../../../assets/images/calendar.svg") no-repeat; width: 25px; height: 25px
}
.issues-search ul.customselect, .issue__list ul.customselect { 
    width: 240px;
    height:50px;
    margin: 0 15px;
    border-radius: 45px !important;
    background-color: rgba(46,57,152,0.14);
    border: none;
    padding-left: 20px;
    line-height: 50px;
    color: #000;background-image: url("../../../assets/images/select-arrow.svg"); background-repeat: no-repeat; background-position: 95%;
}
.issue__list ul.customselect, ul.customselect.status-issue {
    background-image: url("../../../assets/images/white-select-arrow.svg");
    background-repeat: no-repeat;
    background-position: 90%;
}
.issues-search ul.customselect li, .issue__list ul.customselect li { height: 50px;line-height: 50px; z-index: 2; position: relative}
.issues-search ul.customselect li:not(.init), .issue__list ul.customselect li:not(.init) {
    width: 100%;
    display: none;
    background: #E3E3EF;
    color: #000;
    text-align: left; line-height: 50px; height: 50px; padding-left: 15px;    left: -10px;
    top: 10px;
    z-index: 3;
}
.issues-search ul.customselect.show li:not(.init), .issue__list ul.customselect.show li:not(.init) {
    display: list-item;
}
.issues-search ul.customselect li:not(.init):hover, .issue__list ul.customselect li:not(.init):hover, ul li.selected:not(.init) { background: #031083; color: #fff }
.issues-search ul.customselect li.init,.issue__list ul.customselect li.init { cursor: pointer; }

.status-issue {
    background: #F41938;
    width: 150px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    border-radius: 35px;
    margin-right: 20px;
}
.issue__list .status-issue.created, .issue__status .status-issue.created {
    background-color: #F41938;
}
.issue__list .status-issue.completed, .issue__status .status-issue.completed {
    background-color: #00B577;
}
.issue__list .status-issue.in_progress, .issue__status .status-issue.in_progress {
    display: block;
    background-color: #FE9A1B;
}
.issues-search ul.customselect li:last-child, .issue__list ul.customselect li:last-child {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}
.issues-search ul.customselect li:nth-child(2), .issue__list ul.customselect li:nth-child(2) {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
}
.issue__status ul.customselect li:last-child {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.issue__status ul.customselect li:nth-child(2) {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}




/* Single issue */


.title-bar .font-45 a {
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: -4px;
}.issue-details .col-md-3 .issue__info {
    padding-bottom: 40px;
    // border-bottom: 1px solid #DBDBDB;
    // margin-bottom: 40px;
    word-wrap: break-word;
}

.col-md-6.col-sm-12.issue_description.light {
    padding-right: 45px;
    word-wrap: break-word;
}


.issueimages {
    max-width: 650px;
	max-height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
	position: relative;
    z-index: 1;
    margin: auto;
}

.issueimages img{width: 100%;}


.next-prev-arrows a {
    position: absolute;
    top: 50%;
    width: 45px;
    height: 45px; display: none;
}

.next-prev-arrows a.isvisible {
 display: block
}

a.prev {
    left: 0;
}

a.next {
    right: 0;
}


/******************* changes 02-07-20 **********************/

.admin-issues.search-residents .search-field input[type=submit] {
    left: 15px;
    background: url("../../../assets/images/white-Search.svg") no-repeat 0;
    top: 11px;
    background-size: 100%!important;
}
.admin-issues.search-residents .react-datepicker-wrapper:after{
    top: 14px;
}

.issue__list ul.customselect {
    color: #fff;
    padding: 0;
}

.issue__list ul.customselect li {
    line-height: 50px;
}
.issue__list ul.customselect li:not(.init) {
    left: 0;
    // color: #fff;
    // background-color: inherit;
}

.issue__status ul.customselect {
    width: 150px;
    text-align: center;
    height: 35px;
    line-height: 35px;
    border-radius: 35px;
    padding-left: 0;
}

.issue__status ul.customselect li {
    height: inherit;
    line-height: inherit;
    color: #fff;
}

.issue__status ul.customselect li:not(.init) {
    width: 100%;
    // background-color: inherit;
    // color: #fff;
    text-align: left;
    line-height: inherit;
    height: inherit;
    padding-left: 15px;
    left: 0;
    top: 5px;
    z-index: 3;
}

.issue_list .issue_name {
    width: 15%;
}
.issue_list .case-number {
    width: 10%;
}
.issue_list .resident_name {
    width: 15%;
}
.issue_list .level-room {
    width: 10%;
}
.issue_list .issue-date {
    width: 15%;
}
.issue_list .issue-view {
    width: 5%;
    
    img {
        width: 24px;
    }
}
.issue_list .issue-status {
    width: 25%;
}





// .edit-issue-btn {
//     border: none;
//     width: 150px;
//     height: 35px;
//     border-radius: 35px;
//     margin-right: 20px;
// }
.edit-issue-link {
    text-decoration: underline;
    color: #000B8C;
    margin-right: 20px;
}

.issue-basic-details {
    border-bottom: 1px solid #DBDBDB;
    margin-bottom: 20px;
}